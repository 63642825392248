<template>
  <div class="billing-page">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ValidationObserver v-slot="{ handleSubmit, invalid, touched }" ref="updateBillingForm">
              <form class="authentication-form" @submit.prevent="handleSubmit(updateBilling)">
                <div class="row">
                  <div class="col-md-12 mb-3 mb-3">
                    <h4 class="h4">Billing Information</h4>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="form-control-label">Cost Per SMS</label>
                      <div>
                        <span class="font-weight-bold">$ {{ costPerSms }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="form-control-label">Cost Per MMS</label>
                      <div>
                        <span class="font-weight-bold">$ {{ costPerMms }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="form-control-label">Cost Per Minute</label>
                      <div>
                        <span class="font-weight-bold">$ {{ costPerCall }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="form-control-label">Outbound Carrier Fee</label>
                      <div>
                        <a href="javascript:;" class="font-weight-bold text-primary" @click="onClickFeeLink"><u>Learn More...</u></a>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="form-control-label">Number Provisioning</label>
                      <div>
                        <span class="font-weight-bold">$ {{ numberProvisioning }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-4 mb-4">
                  <div class="col-md-12">
                    <h5 class="h5">Current Balance</h5>
                    <div class="row">
                      <div class="col-md-12">
                        <!-- eslint-disable vue/no-v-html -->
                        <p class="font-size-20" v-html="balance"></p>
                        <!--eslint-enable-->
                      </div>
                      <div class="col-md-12">
                        <b-button variant="primary" :disabled="loadingUpdate" type="button"
                          @click="showAddFundModal = true">
                          <b-spinner v-if="loadingUpdate" small />
                          <span v-else>Add Fund</span>
                        </b-button>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-4">
                    <h5 class="h5">Auto Recharge</h5>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="d-flex mb-3">
                          <b-form-checkbox v-model="settings.auto_charge_enabled" switch></b-form-checkbox>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <h6 class="h6">Recharge Balance To</h6>
                        <p>Minimum amount is $20.00 and Maximum amount is $2000.00</p>
                        <TextInput v-model="settings.recharge_to" :min="20" :max="2000" type="number" name="RechargeTo"
                          rules="required" />
                      </div>
                      <div class="col-md-4">
                        <h6 class="h6">When Balance Falls Below <span v-b-tooltip.hover class="cursor-pointer"
                            title="This value should be equal or less than `Recharge Balance To` - 5"><i
                              class="uil uil-question-circle"></i></span></h6>
                        <p>Minimum amount is $10.00 and Maximum amount is $1990.00</p>
                        <TextInput v-model="settings.recharge_threshold" :min="Math.min(10, settings.recharge_to - 5)"
                          :max="Math.min(1990, settings.recharge_to - 5)" type="number" name="RechargeThreshold"
                          rules="required" />
                      </div>
                      <div class="col-md-4">
                        <h6 class="h6">Total Recharge Amount</h6>
                        <p>Maximum amount that can be charged ( Daily )</p>
                        <TextInput v-model="settings.max_recharge_daily" :min="0" :max="2000" type="number"
                          name="TotalRechargeAmount" rules="required" />
                      </div>
                    </div>
                  </div>
                </div>
                <b-form-group>
                  <b-button variant="primary" :disabled="loadingUpdate || (invalid && touched)" type="submit">
                    <b-spinner v-if="loadingUpdate" small />
                    <span v-else>Update</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="add-fund" v-model="showAddFundModal" title="Add Fund">
      <div class="row">
        <div class="col-md-12">
          <TextInput v-model="fund" type="number" label="Payment Amount" name="PaymentAmount" rules="required" />
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showAddFundModal = false">Cancel</b-button>
        <b-button variant="primary" :disabled="!fund || loadingUpdate" @click="handleAddFund">
          <b-spinner v-if="loadingUpdate" small></b-spinner>
          <span v-else>Add <span v-if="fund">{{ '$' + fund }}</span> To My Balance</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  components: {
  },

  data() {
    return {
      user: {
      },
      loadingUpdate: false,
      loadingMethods: false,
      settings: {
        auto_charge_enabled: false,
      },
      showAddFundModal: false,
      fund: null,
      costPerSms: '',
      costPerMms: '',
      costPerCall: '',
      outboundCarrierFee: '',
      numberProvisioning: '',
    }
  },

  computed: {
    balance() {
      const balance = this.user && this.user.balance ? this.user.balance : 0;
      return '<span class="' + (balance >= 0 ? 'text-success' : 'text-danger') + '">$ <b>' + balance.toFixed(3) + '</b></span>';
    },
  },

  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user'])
    if (this.user)
      this.initData();
  },

  methods: {
    initData() {
      this.settings = {
        auto_charge_enabled: this.user.settings.auto_charge_enabled,
        recharge_to: this.user.settings.recharge_to,
        recharge_threshold: this.user.settings.recharge_threshold,
        max_recharge_daily: this.user.settings.max_recharge_daily,
      }

      this.costPerSms = this.user.settings.cost_per_sms
      this.costPerMms = this.user.settings.cost_per_mms
      this.costPerCall = this.user.settings.cost_per_call
      this.outboundCarrierFee = this.user.settings.outbound_carrier_fee
      this.numberProvisioning = this.user.settings.cost_number_provision
    },

    onClickFeeLink() {
      this.$router.push({ name: 'agency.settings', query: {page: 'carrier'} })
    },

    handleAddFund() {
      this.loadingUpdate = true

      const params = {
        'amount': this.fund,
      }

      this.$store
        .dispatch('subscription/chargeBalance', params)
        .then((res) => {
          this.user = {
            ...res
          }
          this.fund = undefined
          this.loadingUpdate = false
          this.showAddFundModal = false
          this.initData()
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.updateForm.setErrors(err.response.data.errors)
          }
          this.loadingUpdate = false
        })
    },

    updateBilling() {
      this.loadingUpdate = true

      const param = {
        settings: this.settings,
      }

      this.$store
        .dispatch('user/updateSetting', param)
        .then(() => {
          this.loadingUpdate = false
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.updateForm.setErrors(err.response.data.errors)
          }
          this.loadingUpdate = false
        })
    },
  }
}
</script>d