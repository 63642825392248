<template>
  <div class="integration-page">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 mb-3">
                <h4 class="h4">International SMS Setting</h4>
              </div>
              <div class="col-md-12">
                <p>In order to text between two countries you will need to enable those countries in your Twilio
                  console. Here is a training video on how to enable country to country text messaging via the Twilio
                  console.</p>
                <a href="javascript:;" class="d-flex align-items-center" @click.prevent="openVideoModal">
                  <strong style="color: #333">Learn how to enable SMS GEO Permission in your agency account.</strong>
                  <feather style="height: 40px" type="video" class="icon-primary ml-2" />
                </a>
              </div>
              <div class="col-md-12 mt-2">
                <span>Click the link below to choose the countries you want to enable.</span>
                <div><b><a class="text-primary" href="https://www.twilio.com/console/sms/settings/geo-permissions"
                      target="blank">https://www.twilio.com/console/sms/settings/geo-permissions</a></b></div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-md-12 mt-3 mb-3">
                <h4 class="h4">Twilio</h4>
                <p>Twilio Information not editable after setup. Contact Support if you need to change these values.
                </p>
              </div>
              <div class="col-md-12">
                <div v-if="twilioConfig" class="row">
                  <div class="col-md-6">
                    <TextInput v-model="twilioConfig.sid" label="Account sid" :readonly="true" />
                  </div>
                  <div class="col-md-6">
                    <TextInput v-model="twilioConfig.token" label="Auth token" :readonly="true" />
                  </div>
                </div>
                <div v-else class="row">
                  <div class="col-md-12">
                    <b-button variant="primary" :disabled="loadingUpdate" type="button"
                      @click="showTwilioConfigModal = true">
                      <b-spinner v-if="loadingUpdate" small />
                      <span v-else><i class="uil uil-upload-alt"></i> Set Twilio Config</span>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-md-12 mt-3 mb-3">
                <h4 class="h4">SendGrid</h4>
                <p>Allow system to send email using Sendgrid.</p>
                <div class="mt-4">
                  <strong>Tracking incoming emails <span class="text-danger">( important )</span></strong>
                  <span class="d-block">To track incoming emails for Inbox and Automation, you will need to update your sender DNS as follows:</span>
                  <table class="mt-2">
                    <tr>
                      <th style="width: 80px;">Type</th>
                      <th style="width: 80px;">Name</th>
                      <th style="width: 120px;">Priority</th>
                      <th style="width: 80px;">Value</th>
                    </tr>
                    <tr>
                      <td>MX</td>
                      <td>email</td>
                      <td>10</td>
                      <td>mx.sendgrid.net</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="col-md-12 mt-3">
                <div v-if="sendgridIntegration" class="row">
                  <div class="col-md-6">
                    <TextInput v-model="sendgridIntegration.api_key" label="Api Key" :readonly="true" />
                  </div>
                  <div class="col-md-6">
                    <TextInput v-model="sendgridIntegration.options.from_email" label="From Email" :readonly="true" />
                  </div>
                  <div class="col-md-6 mb-5">
                    <label>Enabled</label>
                    <b-form-checkbox v-model="sendgridIntegration.active" name="check-button" switch
                      @change="handleChangeActive('sendgrid')">
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <b-button variant="primary" :disabled="loadingUpdate" type="button"
                      @click="showSendgridIntegrationModal = true">
                      <b-spinner v-if="loadingUpdate" small />
                      <span v-else><i class="uil uil-upload-alt"></i> {{ sendgridIntegration ? 'Update' : 'Set' }}
                        SendGrid Config</span>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-md-12 mt-3">
                <h4 class="h4">AWS SES</h4>
                <p>Allow system to send email using AWS SES.</p>
              </div>
              <div class="col-md-12 mt-3">
                <div v-if="sesIntegration" class="row">
                  <div class="col-md-6">
                    <TextInput v-model="sesIntegration.key" label="Access Key" :readonly="true" />
                  </div>
                  <div class="col-md-6">
                    <TextInput v-model="sesIntegration.secret" label="Secret Key" :readonly="true" />
                  </div>
                  <div class="col-md-6">
                    <TextInput v-model="sesIntegration.region" label="Region" :readonly="true" />
                  </div>
                  <div class="col-md-6">
                    <TextInput v-model="sesIntegration.options.from_email" label="From Email" :readonly="true" />
                  </div>
                  <div class="col-md-6 mb-5">
                    <label>Enabled</label>
                    <b-form-checkbox v-model="sesIntegration.active" name="check-button" switch
                      @change="handleChangeActive('ses')">
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <b-button variant="primary" :disabled="loadingUpdate" type="button"
                      @click="showSesIntegrationModal = true">
                      <b-spinner v-if="loadingUpdate" small />
                      <span v-else><i class="uil uil-upload-alt"></i> {{ sesIntegration ? 'Update' : 'Set' }}
                        AWS SES Config</span>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showTwilioConfigModal" title="Set Twilio Credential" size="lg" hide-footer>
      <ValidationObserver v-slot="{ handleSubmit, invalid, touched }" ref="twilioForm">
        <form class="twilio-form" @submit.prevent="handleSubmit(updateTwilio)">
          <div class="row">
            <div class="col-md-6">
              <TextInput v-model="twilio.sid" name="TwilioSid" rules="required" label="Account sid" />
            </div>
            <div class="col-md-6">
              <TextInput v-model="twilio.token" name="TwilioToken" rules="required" label="Auth token" />
            </div>
          </div>
          <div class="modal-footer">
            <b-button variant="light" @click="showTwilioConfigModal = false">Close</b-button>
            <b-button variant="primary" :disabled="loadingUpdate || (invalid && touched)" type="submit">
              <b-spinner v-if="loadingUpdate" small />
              <span v-else>Update</span>
            </b-button>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
    <b-modal v-model="showSendgridIntegrationModal" title="Set SendGrid Credential" size="lg" hide-footer>
      <ValidationObserver v-slot="{ handleSubmit, invalid, touched }" ref="sendgridForm">
        <form class="sendgrid-form" @submit.prevent="handleSubmit(updateSendgrid)">
          <div class="row">
            <div class="col-md-12">
              <TextInput v-model="sendgrid.api_key" name="apiKey" label="Api Key" />
            </div>
            <div class="col-md-12">
              <TextInput v-model="sendgrid.options.from_email" type="email" name="fromEmail" label="From Email" />
            </div>
          </div>
          <div class="modal-footer">
            <b-button variant="light" @click="showSendgridIntegrationModal = false">Close</b-button>
            <b-button variant="primary" :disabled="loadingUpdate || (invalid && touched)" type="submit">
              <b-spinner v-if="loadingUpdate" small />
              <span v-else>Update</span>
            </b-button>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
    <b-modal v-model="showSesIntegrationModal" title="Set AWS SES Credential" size="lg" hide-footer>
      <ValidationObserver v-slot="{ handleSubmit, invalid, touched }" ref="sendgridForm">
        <form class="ses-form" @submit.prevent="handleSubmit(updateSes)">
          <div class="row">
            <div class="col-md-12">
              <TextInput v-model="ses.options.key" name="Access Key" label="Access Key" />
            </div>
            <div class="col-md-12">
              <TextInput v-model="ses.api_key" name="Secret Key" label="Secret Key" />
            </div>
            <div class="col-md-6">
              <label>Region</label>
              <multiselect
                v-model="ses.options.region"
                :options="awsRegionList"
                placeholder="Select Region"
                label="label"
                :multiple="false"
                track-by="id"
                :allow-empty="false"
                :show-labels="false"
              ></multiselect>
            </div>
            <div class="col-md-6">
              <TextInput v-model="ses.options.from_email" name="From Email" label="From Email" />
            </div>
          </div>
          <div class="modal-footer">
            <b-button variant="light" @click="showSesIntegrationModal = false">Close</b-button>
            <b-button variant="primary" :disabled="loadingUpdate || (invalid && touched)" type="submit">
              <b-spinner v-if="loadingUpdate" small />
              <span v-else>Update</span>
            </b-button>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
    <b-modal v-model="showVideoModal" title="How to Enable SMS GEO permission in your Agency Account?" size="lg">
      <div style="height:500px;">
        <iframe src="https://player.vimeo.com/video/454064274?autoplay=1&loop=1&title=0&byline=0&portrait=0"
          style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen"
          allowfullscreen></iframe>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="success" @click="showVideoModal = false">Close</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import { AWS_REGIONS } from '@src/constants'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect,
  },

  data() {
    return {
      awsRegionList: AWS_REGIONS,
      showVideoModal: false,
      loadingUpdate: false,
      showTwilioConfigModal: false,
      showSendgridIntegrationModal: false,
      showSesIntegrationModal: false,
      user: {
        agency: {
          twilioConfig: {},
          sendgridIntegration: {
            options: {},
          },
          sesIntegration: {
            options: {
            },
          },
          whitelabel: {},
        },
      },
      twilio: {},
      sendgrid: {
        options: {
        },
      },
      ses: {
        options: {
        },
      },
    }
  },

  computed: {
    twilioConfig() {
      return this.user && this.user.agency && this.user.agency.twilioConfig
    },

    sendgridIntegration() {
      return this.user && this.user.agency && this.user.agency.sendgridIntegration
    },

    sesIntegration() {
      return this.user && this.user.agency && this.user.agency.sesIntegration
    },
  },

  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user'])
    this.initData();
  },

  methods: {
    initData() {
      this.twilio = {}
      this.sendgrid = {
        options: {
        },
      }
      this.ses = {
        options: {
        },
      }
    },

    openVideoModal() {
      this.showVideoModal = true
    },

    updateTwilio() {
      this.loadingUpdate = true;
      this.$store
        .dispatch('agency/updateTwilio', this.twilio)
        .then(() => {
          this.loadingUpdate = false
          this.showTwilioConfigModal = false
          this.initData()
        })
        .catch(() => {
          this.loadingUpdate = false
        })
    },

    updateSendgrid() {
      this.loadingUpdate = true;
      this.$store
        .dispatch('agency/updateSendgrid', this.sendgrid)
        .then((res) => {
          if (res.api_key)
            this.user.agency.sendgridIntegration = res
          else
            this.user.agency.sendgridIntegration = null

          this.loadingUpdate = false
          this.showSendgridIntegrationModal = false
          this.initData()
        })
        .catch(() => {
          this.loadingUpdate = false
        })
    },

    updateSes() {
      this.loadingUpdate = true;
      this.$store
        .dispatch('agency/updateSes', this.ses)
        .then((res) => {
          if (res.secret)
            this.user.agency.sesIntegration = res
          else
            this.user.agency.sesIntegration = null

          this.loadingUpdate = false
          this.showSesIntegrationModal = false
          this.initData()
        })
        .catch(() => {
          this.loadingUpdate = false
        })
    },

    handleChangeActive(type) {
      switch (type) {
        case 'sendgrid':
          if (this.sendgridIntegration)
            this.changeIntegrationStatus(this.sendgridIntegration.id)
          break;
        case 'ses':
          if (this.sesIntegration)
            this.changeIntegrationStatus(this.sesIntegration.id)
          break;
        default:
          break;
      }
    },

    changeIntegrationStatus(id) {
      this.$store
        .dispatch('integration/toggleStatus', id)
        .then((integration) => {
        })
        .catch(() => {
        })
    }
  }
}
</script>