<template>
  <div class="settingsPage">
    <div v-if="loading" class="text-center">
      <b-spinner></b-spinner>
    </div>
    <div v-else-if="user" class="row">
      <b-tabs>
        <b-tab title="Settings" :active="page === 'setting'" @click="onTabClick('setting')">
          <div class="card">
            <div class="card-body">
              <ValidationObserver v-slot="{ handleSubmit, invalid, touched }" ref="updateForm">
                <form class="authentication-form" @submit.prevent="handleSubmit(updateProfile)">
                  <div class="row">
                    <div class="col-md-12 mb-3">
                      <h4 class="h4">Company Info</h4>
                    </div>
                    <div class="col-md-4">
                      <TextInput v-model="user.agency.name" name="company_name" rules="required" label="Company name" />
                    </div>
                    <div class="col-md-4">
                      <TextInput v-model="user.address" name="address" label="Address" />
                    </div>
                    <div class="col-md-4">
                      <PhoneInput v-model="user.phone_number" name="phone" label="Phone Number" rules="required|phone"
                        :disabled-fetching-country="true" />
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-md-12 mb-3">
                      <h4 class="h4">White Label</h4>
                    </div>
                    <div class="col-md-6">
                      <TextInput v-model="user.agency.whitelabel.domain" rules="customurl" name="whitelabel.domain"
                        label="Custom Url" :explain="true" @clickExplain="handleExplain" />
                    </div>
                    <div class="col-md-6">
                      <TextInput label="Subdomain" :value="`${user.agency.subdomain}.${user.agency.subdomain_base}`
                        " :readonly="true" />
                    </div>
                    <div class="col-md-4 mb-2 boxFile">
                      <TextInput :hide-input="user.agency.whitelabel.header_logo" type="file" name="header_logo"
                        label="Header Logo ( 250 x 50 )" accept="image/*"
                        @changed="(file) => choosedFile(file, 'header_logo')" />
                      <div class="text-center">
                        <img v-if="user.agency.whitelabel.header_logo" :src="user.agency.whitelabel.header_logo"
                          style="height:50px;" />
                        <div v-if="user.agency.whitelabel.header_logo" class="mt-2">
                          <button class="btn btn-outline-danger btn-sm" :disabled="loadingRemove !== null"
                            @click="deleteWhitelableFile('header_logo')">
                            <b-spinner v-if="loadingRemove === 'header_logo'" small />
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-md-4 mb-2 boxFile">
                    <TextInput
                      :hide-input="user.agency.whitelabel.login_page_logo"
                      type="file"
                      name="login_page_logo"
                      label="Logo with text"
                      accept="image/*"
                      @changed="(file) => choosedFile(file, 'login_page_logo')"
                    />
                    <div class="text-center">
                      <img
                        v-if="user.agency.whitelabel.login_page_logo"
                        :src="user.agency.whitelabel.login_page_logo"
                        style="height:50px;"
                      />
                      <div
                        v-if="user.agency.whitelabel.login_page_logo"
                        class="mt-2"
                      >
                        <button
                          class="btn btn-outline-danger btn-sm"
                          :disabled="loadingRemove !== null"
                          @click="deleteWhitelableFile('login_page_logo')"
                        >
                          <b-spinner
                            v-if="loadingRemove === 'login_page_logo'"
                            small
                          />
                          Delete
                        </button>
                      </div>
                    </div>
                  </div> -->
                    <div class="col-md-4 mb-2 boxFile">
                      <TextInput :hide-input="user.agency.whitelabel.favicon" type="file" name="favicon"
                        label="Favicon ( 128 x 128 )" accept="image/*"
                        @changed="(file) => choosedFile(file, 'favicon')" />
                      <div class="text-center">
                        <img v-if="user.agency.whitelabel.favicon" :src="user.agency.whitelabel.favicon"
                          style="width:50px;height:50px;" />
                        <div v-if="user.agency.whitelabel.favicon" class="mt-2">
                          <button class="btn btn-outline-danger btn-sm" :disabled="loadingRemove !== null"
                            @click="deleteWhitelableFile('favicon')">
                            <b-spinner v-if="loadingRemove === 'favicon'" small />
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-md-12 mb-3">
                      <h4 class="h4">Powered By</h4>
                    </div>
                    <div class="col-md-2 text-center">
                      <label>Enabled</label>
                      <b-form-checkbox v-model="user.agency.whitelabel.powered_enabled" name="check-button" switch>
                      </b-form-checkbox>
                    </div>
                    <div class="col-md-5">
                      <TextInput v-model="user.agency.whitelabel.powered_url" name="url" label="Url" />
                    </div>
                    <div class="col-md-5">
                      <TextInput v-model="user.agency.whitelabel.powered_name" name="name" label="Name" />
                    </div>
                  </div>
                  <b-form-group>
                    <b-button variant="primary" :disabled="loadingUpdate || (invalid && touched)" type="submit">
                      <b-spinner v-if="loadingUpdate" small />
                      <span v-else>Update</span>
                    </b-button>
                  </b-form-group>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </b-tab>
        <b-tab title="Billing" :active="page === 'billing'" @click="onTabClick('billing')">
          <Billing />
        </b-tab>
        <b-tab title="Carrier Fees" :active="page === 'carrier'" @click="onTabClick('carrier')">
          <CarrierFee />
        </b-tab>
        <b-tab title="Integrations" :active="page === 'integration'" @click="onTabClick('integration')">
          <Integration />
        </b-tab>
      </b-tabs>
    </div>
    <b-modal v-model="showDomainModal" title="Custom Domain">
      <p>
        To use your own domain/subdomain, you will need to update your DNS as
        follows:
      </p>
      <p>
        To use a dedicated top level domain ( e.g. yourdomain.com ), change it's
        A value to ours: <br />
        52.70.253.163
      </p>
      <p>
        To use a subdomain ( e.g. reviews.yourdomain.com ), create an A record
        under the domain, like so: <br />
        <strong style="padding-right:100px;">Host Target</strong><strong>Your Subdomain Value</strong><br />
        <span style="padding-right:20px;">reviews.yourdomain.com</span>
        52.70.253.163
      </p>
      <p>Please contact
        <a href="mailto:support@customerengine.io" target="_blank">support@customerengine.io</a>
        if SSL doesn't work on your custom domain in an hour after you submit
        request.
      </p>
      <template v-slot:modal-footer>
        <b-button variant="primary" @click="showDomainModal = false">Got It!</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import Billing from './Billing'
import Integration from './Integration'
import CarrierFee from './CarrierFee'

export default {
  components: {
    Billing, Integration, CarrierFee,
  },

  data() {
    return {
      showVideoModal: false,
      loading: false,
      loadingUpdate: false,
      loadingRemove: null,
      showDomainModal: false,
      user: {
        agency: {
          twilioConfig: {},
          whitelabel: {},
        },
      },
      page: 'setting',
    }
  },

  computed: {
    hasTwilioConfig() {
      return this.user && this.user.agency && this.user.agency.twilioConfig
    },

    tabIndex() {
      return this.$route.query.page
    }
  },

  watch: {
    'tabIndex': function(newVal) {
      if (newVal) {
        this.page = newVal
      }
    }
  },

  mounted() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page
    }
    this.loading = true;
    this.$store.dispatch('auth/getProfile')
      .then((user) => {
        this.user = Vue.util.extend({}, user)
        this.loading = false;
      })
      .catch(() => {
        this.user = Vue.util.extend({}, this.$store.getters['auth/user'])
        this.loading = false;
      })
  },

  methods: {
    onTabClick(index) {
      this.page = index
      this.$router.push({ name: 'agency.settings', query: {page: index} })
    },

    openVideoModal() {
      this.showVideoModal = true
    },

    updateProfile() {
      this.loadingUpdate = true

      let params = {
        company_name: this.user.agency.name,
        address: this.user.address,
        phone: this.user.phone_number,
        whitelabel: this.user.agency.whitelabel,
      }

      this.$store
        .dispatch('agency/editSettings', params)
        .then(() => {
          this.loadingUpdate = false
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.updateForm.setErrors(err.response.data.errors)
          }
          this.loadingUpdate = false
        })
    },

    choosedFile(file, type) {
      let uploadForm = new FormData()
      uploadForm.append('type', type)
      uploadForm.append('file', file)

      this.$store
        .dispatch('agency/uploadWhitelableFile', uploadForm)
        .then((res) => {
          this.user.agency.whitelabel[type] = res.path
        })
        .catch(() => { })

      let reader = new FileReader()

      reader.onload = () => {
        this.user.agency.whitelabel[type] = reader.result
      }

      reader.readAsDataURL(file)
    },

    deleteWhitelableFile(type) {
      this.loadingRemove = type
      this.$store
        .dispatch('agency/deleteWhitelableFile', type)
        .then((res) => {
          this.loadingRemove = null
          this.user.agency.whitelabel[type] = null
        })
        .catch(() => {
          this.loadingRemove = null
        })
    },

    handleExplain() {
      this.showDomainModal = true
    },
  },
}
</script>

<style lang="scss">
.settingsPage {
  .boxFile {
    .input-group {
      background-color: #4284f4;
      border-color: 1px solid #4284f4;
      border-radius: 0.3rem;
      color: #fff;

      input[type='file'] {
        cursor: pointer;
      }

      &::before {
        position: absolute;
        top: 9px;
        left: calc(50% - 45px);
        font-weight: 600;
        color: #fff;
        pointer-events: none;
        content: 'Upload File';
      }

      &:hover {
        background-color: #1e6cf2;
        border-color: #1265f1;

        &::before {
          color: #fff;
        }
      }
    }

    .form-control {
      opacity: 0;
    }
  }
}
</style>
